import { PushBanner } from '@vgno/push-banner';

export default ({ trackEngagement }) => {
    const pushBanner = document.querySelector('push-banner');
    window.addEventListener('vg-nav-offset', ({ detail }) => {
        pushBanner.style.setProperty(
            '--header-offset-top',
            `${detail.offset}px`,
        );
    });
    pushBanner.addEventListener('click', () => {
        trackEngagement({
            type: 'Engagement',
            elementAction: 'Click',
            elementName: pushBanner.dataset.trackName,
            contentId: pushBanner.dataset.trackId,
            elementId: pushBanner.dataset.trackId,
            elementType: pushBanner.dataset.trackElementType,
        });
    });

    customElements.define('push-banner', PushBanner);
};
